<template>
  <div class="propertyTypeAdd">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content bgFFF">
      <div class="commonF padding20">
        <!--搜索条件区域-->
        <h2 class="title">
          <div class="title_icon"></div>
          {{ isEdit ? "编辑" : "添加" }}物品型号
        </h2>
        <div class="">
          <el-form
            ref="propertyForm"
            :inline="false"
            label-position="right"
            :model="formInline"
            class="demo-form-inline"
            label-width="100px"
            :rules="rules"
          >
          <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
            <el-select
              v-model.trim="formInline.operationId"
              filterable
              size="15"
              @change="getAssetsTypeList"
              :disabled="isEdit"
              style="width: 200px;"
            >
              <!-- <el-option label="全部" value=""></el-option> -->
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
            <el-form-item label="所属类型" prop="parentId">
              <el-select
                style="width: 200px;"
                v-model.trim="formInline.parentId"
                filterable
                placeholder="请选择"
              >
                <!-- <el-option label="全部" value=""></el-option> -->
                <el-option
                  :label="item.assetsTypeName"
                  :value="item.assetsTypeId"
                  v-for="item in assetsTypeList"
                  :key="item.assetsTypeId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="型号名称" prop="assetsTypeName">
              <el-input
                :maxlength="20"
                v-model="formInline.assetsTypeName"
                style="width: 200px;"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.remarks')">
              <el-input
                :maxlength="50"
                type="textarea"
                v-model="formInline.remarks"
                style="width: 200px;"
              ></el-input>
            </el-form-item>
            <div style="padding-left: 100px;">
              <el-button type="primary" @click="submit()" :loading="loading"
                >提交</el-button
              >
              <el-button @click="$router.go(-1)" :loading="loading"
                >取消</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rules: {
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "change"
          }
        ],
        parentId: [
          {
            required: true,
            message: "请选择所属类型",
            trigger: "change",
          },
        ],
        assetsTypeName: [
          {
            required: true,
            message: "请输入型号名称",
            trigger: "blur",
          },
        ],
      },
      tenantList: [],
      isEdit: false,
      assetsTypeList: [],
      formInline: {
        type: 1,
        remarks: "",
        parentId: "",
        assetsTypeName: "",
        operationId: "", // 商户id
      },
      loading: false,
    };
  },
  methods: {
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then(res => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    getAssetsTypeList() {
      this.$axios
        .get("/acb/2.0/assetsType/allList", {
          data: {
            type: 0,
            operationId: this.formInline.operationId
          },
        })
        .then((res) => {
          this.assetsTypeList = res.value.list;
        });
    },
    submit() {
      this.$refs.propertyForm.validate((valid) => {
        if (valid) {
          let url = this.$route.query.assetsTypeId
            ? `/acb/2.0/assetsType/update`
            : `/acb/2.0/assetsType/add`;
          this.$axios
            .post(url, {
              data: {
                ...this.formInline,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$router.go("-1");
              } else {
                this.$alert(res.desc, "提示");
              }
            });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getTenantList();
    if (this.$route.query.assetsTypeId) {
      this.isEdit = true;
      this.formInline.assetsTypeId = this.$route.query.assetsTypeId;
      for (let i in this.formInline) {
        this.formInline[i] = this.$route.query[i];
      }
      this.getAssetsTypeList();
    }
  },
};
</script>
<style lang="stylus" scoped>
.propertyTypeAdd {
  .breadcrumb {
    height 30px;
  }
  .content {
    overflow: hidden;
    border: 1px solid #c0ccda;
    border-radius: 4px;
    padding: 20px;
    .searchWrapper {
      padding-left 30px;
    }
  }
}
</style>
